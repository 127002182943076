import React from 'react';
import s from './styles.module.css'
import Select from "react-select";

const CustomSelect = ({
                          isFixed = false,
                          isMulti = false,
                          closeMenuOnSelect = true,
                          options,
                          disabled = false,
                          menuPlacement,
                          defaultValue,
                          handleChange,
                          placeholder,
                          value,
                          name,
                      }: any) => {
    const colourStyles = {
        control: (styles: any) => ({
            display: 'flex',
            width: '100%',
            backgroundColor: '#1F1F1F',
            border: '1px solid #3C3C3C',
            borderRadius: '5px',
            cursor: 'pointer',
            minHeight: '30px',
            overflow: 'hidden',
            transition: '.4s all',
            position: 'relative'
        }),

        singleValue: (styles: any) => ({
            ...styles,
            fontWeight: '600',
            fontSize: '12px',
            color: '#fff',
            fontFamily: 'Montserrat, sans-serif',
            transition: '.4s all',
        }),
        menu: (styles: any) => ({
            ...styles,
            boxSizing: 'border-box !important',
            backgroundColor: '#1C1B1A',
            borderRadius: '8px',
            border: '1px solid #3C3C3C',
            transition: '.4s all',
        }),
        option: (styles: any, state: any) => {

            return {
                ...styles,
                padding: '10px',
                cursor: 'pointer',
                lineHeight: '120%',
                backgroundColor: state?.isSelected ? '#2A3336' : '#1C1B1A',
                // color: 'rgba(251, 251, 251, 1)',
                fontWeight: '600',
                fontSize: '14px',
                color: state?.isSelected ? '#56CCF2' : '#BDBDBD',
                fontFamily: 'Montserrat, sans-serif',
                '&:hover': {
                    backgroundColor: state?.isSelected ? '#2A3336' : 'rgba(53,54,64,0.75)',
                },
                transition: '.4s all',
            }
        },
        menuPortal: (styles: any) => ({
            ...styles,
            zIndex: 99999,
        }),
        placeholder: (styles: any) => ({
            ...styles,
            fontWeight: '600',
            fontSize: '12px',
            color: '#BDBDBD',
            fontFamily: 'Montserrat, sans-serif'
        }),
    };
    return (
        <div className={s.select}>
            <Select isSearchable={false}
                    isMulti={isMulti}
                    menuPortalTarget={document.body}
                    name={name}
                    isDisabled={disabled}
                    closeMenuOnSelect={closeMenuOnSelect}
                    menuPlacement={menuPlacement}
                    menuPosition={isFixed ? 'fixed' : 'absolute'}
                //@ts-ignore
                    styles={colourStyles}
                    options={options}
                    value={value}
                    onChange={handleChange}
                    defaultValue={defaultValue} placeholder={placeholder}/>

        </div>
    );
};

export default CustomSelect;