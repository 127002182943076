import React, {createContext, useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import InfoBlock from "./components/info_block";
import ChatWithSettingsv2 from "../../../../entities/components/chartWithSettingsv2";
import TableBlock from "./components/table_block";
import SettingsSpeculative from "./setings";
import classNames from "classnames";
import {NavLink, useSearchParams} from "react-router-dom";
import {ReactComponent as DashboardIcon} from "../../../../shared/assets/images/dashboard.svg";
import {ReactComponent as TradeIcon} from "../../../../shared/assets/images/trade.svg";
import {toast} from "react-toastify";
import {useGetAllSpeculateQuery, useGetCurrentSymbolMutation} from "../../../../store/API/chartApi";
import {DashboardContext} from "../../dashboardTabs";
import {Skeleton} from "@mui/material";

export const ToolsContext = createContext<any>(null)

const TradeSpeculative = () => {
    const [settingChartData, setSettingChartData] = useState(null)
    const [tools, setTools] = useState<any>('')
    const [paramsSearch, setParamsSearch] = useSearchParams()

    const [lineIndicator,setLineIndicator] = useState(null)
    console.log(lineIndicator)
    const {dataDashboard} = useContext(DashboardContext)

    const [tableChoose, setTableChoose] = useState(1)

    const [settingChart, setSettingChart] = useState<any>(null)
    const [isLoadingSymbol, setIsLoadingSymbol] = useState(true)

    const [getCurrentSymbol] = useGetCurrentSymbolMutation()
    const {data, isLoading, refetch: refetchTable, isFetching: isFetchingTable} = useGetAllSpeculateQuery({
        idProduct: dataDashboard?.product?.product_data?.id,
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: !dataDashboard?.product?.product_data?.id
    })

    const handleChangeSettingChart = (id: any, symbol: any, description: any) => {
        if (settingChart) {
            setSettingChart({...settingChart, id: id, symbol: symbol, description: description})
        } else {
            setSettingChart({id: id, symbol: symbol, description: description})
        }
    }
    console.log(settingChart)

    useEffect(() => {
        if (!tools && paramsSearch.get('symbolID')) {
            getCurrentSymbol({idSymbol: paramsSearch.get('symbolID')})
                .unwrap()
                .then((res) => {
                    console.log(res)
                    setTools(res?.data)
                    setIsLoadingSymbol(false)
                })
                .catch(e => {
                    paramsSearch.delete('symbolID')
                    setParamsSearch(paramsSearch)
                    console.log(e)
                    setIsLoadingSymbol(false)
                })
        } else {
            setIsLoadingSymbol(false)
        }
    }, [tools, paramsSearch])

    if (isLoadingSymbol) {
        return null
    }
    return (
        <ToolsContext.Provider value={{
            setTools: setTools,
            tools: tools
        }}>
            <div className={s.main}>
                <div className={s.left_side}>
                    <ChatWithSettingsv2 lineIndicator={lineIndicator} setSettingChart={setSettingChart} indicatorData={settingChartData}/>
                    <InfoBlock/>

                    <div className={s.table_wrapper}>
                        <div className={s.table_navigate}>
                            <div onClick={() => setTableChoose(1)}
                                 className={classNames(
                                     s.navigate_item,
                                     tableChoose === 1 && s.navigate_item_active
                                 )}>
                                <DashboardIcon/>
                                <p className={s.title_item}>Настройки</p>
                            </div>
                            <div onClick={() => toast.warning('В разработке')}
                                 className={classNames(
                                     s.navigate_item,
                                     tableChoose === 2 && s.navigate_item_active
                                 )}>
                                <TradeIcon/>
                                <p className={s.title_item}>Открытые позиции</p>
                            </div>
                        </div>
                        {isLoading ? <Skeleton variant="rectangular" width={'100%'} height={300}/> :
                            <TableBlock setTools={setTools} isFetchingTable={isFetchingTable}
                                        refetchTable={refetchTable}
                                        handleChangeSettingChart={handleChangeSettingChart}
                                        dashboardID={dataDashboard?.product?.product_data?.id} data={data?.data}
                                        idCurrentSetting={settingChart?.id}/>}
                    </div>
                </div>

                <SettingsSpeculative lineIndicator={lineIndicator} setLineIndicator={setLineIndicator} refetchTable={refetchTable} settingChart={settingChart}
                                     setSettingChartData={setSettingChartData}/>
            </div>
        </ToolsContext.Provider>
    );
};

export default TradeSpeculative;