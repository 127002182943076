import React, {FC, useEffect, useState} from 'react';
import Navigation from "../entities/components/navigation";
import HeaderSidebar from "../entities/components/headerSidebar";
import {IconButton, Stack, useMediaQuery} from "@mui/material";
import IconMenu from "../shared/assets/images/icons/iconMenu";
import logo from '../shared/assets/images/logo.svg'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const style = {
    maxWidth: 300,
    width: `100%`,
    padding: `0 10px`,
    borderRight: `4px solid #1F1F1F;`,
    position: 'fixed',
    top: 28,
    overflowY: 'scroll',
    transition: '.3s all'
}
const styleMediaQuery = {
    height: `100vh`,
    maxWidth: 'unset',
    padding: `0 10px`,
    width: `100%`,
    zIndex: 999,
    top: `65px`,
    left: 0,
    background: `#212121`,
    position: 'fixed',
}

interface T {
    isAdmin?: boolean;
    hiddeSideBar?: any;
    setHiddeSideBar?: any;
}

const Sidebar: FC<T> = ({isAdmin, hiddeSideBar, setHiddeSideBar}) => {
    const mediaQuery = useMediaQuery('(min-width:1220px)');
    const [visibleMenu, setVisibleMenu] = useState(true)

    useEffect(() => {
        const html = document.getElementById('html')

        if (visibleMenu && !mediaQuery) {
            window.scrollTo({top: 0})
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'relative';
            html!.style.overflow = 'hidden';
            html!.style.height = '100vh';
            document.body.style.height = '100vh';
        } else {
            window.scrollTo({top: 0})
            document.body.style.overflow = 'unset';
            document.body.style.position = 'static';
            document.body.style.height = '100%';
            html!.style.overflow = 'unset';
            html!.style.height = '100%';
        }
    }, [visibleMenu, mediaQuery])

    useEffect(() => {
        if (!mediaQuery) {
            setVisibleMenu(false)
        } else {
            setVisibleMenu(true)
        }
    }, [mediaQuery])

    const handleMenu = () => {
        setVisibleMenu(!visibleMenu)
    }

    return (
        <>
            <Stack>

                {
                    !mediaQuery ?
                        <Stack direction="row" alignItems="center" justifyContent="space-between"
                               sx={{
                                   ml: -5,
                                   mr: -5,
                                   height: `65px`,
                                   padding: `14px 28px`,
                                   background: `#212121`,
                               }}>
                            <IconButton onClick={handleMenu}>
                                <IconMenu active={visibleMenu}/>
                            </IconButton>
                            <img src={logo} alt="logo"/>
                        </Stack>
                        : null
                }
                {
                    visibleMenu ?

                        <Stack spacing={7}
                               sx={!mediaQuery ? styleMediaQuery : {...style, width: hiddeSideBar ? 100 : 300}}>
                            <Stack spacing={7}>
                                <HeaderSidebar isAdmin={isAdmin} hiddeSideBar={hiddeSideBar}/>
                            </Stack>
                            <Stack>
                                <Navigation setVisibleMenu={setVisibleMenu} hiddeSideBar={hiddeSideBar}
                                            isAdmin={isAdmin}/>

                                <div className={'hidden_button'}>
                                    <IconButton sx={{width: 'fit-content'}}
                                                onClick={() => setHiddeSideBar(!hiddeSideBar)}>
                                        <MenuOpenIcon
                                            sx={{transition: '.3s all', rotate: !hiddeSideBar ? '0' : '180deg'}}/>
                                    </IconButton>
                                </div>
                            </Stack>
                        </Stack>

                        : null
                }

            </Stack>
        </>
    );
};

export default Sidebar;
