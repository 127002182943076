import React from 'react';
import s from './styles.module.css';
import Paper from '@mui/material/Paper';
import {CircularProgress, Stack} from '@mui/material';
import DashboardLabel from '../../entities/components/dashboardLabel';
import TransactionsLabel from '../../entities/components/transactionsLabel';
import Chart from '../../entities/components/chart/chart';
import BalanceChart from '../../entities/components/chart/chartBalance';
import ChartGrowth from '../../entities/components/chart/chartGrowth';
import ChartBalanceDropDown from '../../entities/components/chart/chartBalanceDropDown';
import ChartDropDown from '../../entities/components/chart/chartDropdown';
import ChartTrade from '../../entities/components/chart/chartTrade';
import SymbolChart from '../../entities/components/chart/symbolChart';
import CustomBarChart from '../../entities/components/chart/customBarChart';
import DashboardTable from '../../shared/components/dashboardTable';
import DashboardTradersSidebar from '../dashboardTradersSidebar';
import TradersAndSets from '../tradersAndSets';
import Button from '@mui/material/Button';
import RobotSettings from '../robotSettings';
import ChatWithSettingsv2 from "../../entities/components/chartWithSettingsv2";
import ChatWidjet from "../../entities/components/chartWidjet";
import StockHeader from "./stockHeader";

const Tabs1 = ({
                   isTraderDasboard,
                   isLoadingAccount,
                   updateDeposit,
                   traderDashboard,
                   dataTrader,
                   dataDashboard,
                   balanceChartData,
                   balanceChartUrl,
                   dataBalanceGain,
                   balanceGainChartUrl,
                   dataChartDrawdownAndGain,
                   drawdownAndGainChartUrl,
                   dataChartDrawdown,
                   drawdownChartUrl,
                   dataTradersChart,
                   tradersChartUrl,
                   dataSymbol,
                   barChartData,
                   dataChartDay,
                   dataChartHours,
                   dataChartMonths,
                   sidebarVisible,
                   mediaQuery,
                   setSidebarVisible,
                   isStock,
               }: any) => {
    console.log(isStock)
    return (
        <>
            <div className={s.content}>
                <div className={s.content_left}>

                    {isStock && <StockHeader dataDashboard={dataDashboard}/>}

                    {(traderDashboard && !isStock) && (
                        <Paper>
                            <Stack className='subHeaders white-80' spacing={7}>
                                <p>{dataTrader?.description}</p>
                            </Stack>
                        </Paper>
                    )}

                    {!isStock && <DashboardLabel
                        accountType={dataDashboard && dataDashboard?.server.type}
                        notifications={dataDashboard?.notifications}
                        balance={dataTrader ? dataTrader?.stats?.balance : dataDashboard?.stats?.balance}
                    />}

                    {!isStock && <TransactionsLabel isTrader={dataTrader && true}
                                                    data={dataTrader ? dataTrader?.stats : dataDashboard?.stats}/>}

                    {balanceChartData && (
                        <Chart title='Баланс' changeTime={balanceChartUrl}>
                            <BalanceChart balanceChartData={balanceChartData}/>
                        </Chart>
                    )}
                    {/*dataChartMonths?.years?.length > 0*/}
                    {dataChartMonths && (
                        <Paper>
                            <Stack className='h2 white-90' sx={{mb: 7}}>
                                По месяцам
                            </Stack>

                            <DashboardTable
                                data={dataChartMonths}
                                dataTableHead={['Год', 'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек', 'Всего']}
                            />
                        </Paper>
                    )}

                    {dataBalanceGain && (
                        <Chart title='Прирост (%)' changeTime={balanceGainChartUrl}>
                            <ChartGrowth dataBalanceGain={dataBalanceGain}/>
                        </Chart>
                    )}
                    {(dataChartDrawdownAndGain && !isStock) && (
                        <Chart title='Прирост-просадка ($)' currentTime={!isTraderDasboard ? true : false}
                               changeTime={drawdownAndGainChartUrl}>
                            <ChartBalanceDropDown dataChartDrawdownAndGain={dataChartDrawdownAndGain}/>
                        </Chart>
                    )}
                    {(dataChartDrawdown && !isStock) && (
                        <Chart title='Просадка' changeTime={drawdownChartUrl}>
                            <ChartDropDown dataChartDrawdown={dataChartDrawdown}/>
                        </Chart>
                    )}
                    {(dataTradersChart && !isStock) && (dataDashboard?.product?.product_data?.type === 0 || !dataDashboard?.product?.product_data?.type) && (
                        <Chart title='По трейдерам' changeTime={tradersChartUrl} isTrader={true}>
                            <ChartTrade dataTradersChart={dataTradersChart}/>
                        </Chart>
                    )}
                    {(dataSymbol?.length > 0 && !isStock) && isTraderDasboard && <SymbolChart data={barChartData}/>}

                    {(dataChartDay?.length > 0 && !isStock) && isTraderDasboard && (
                        <Chart title='По дням'>
                            <CustomBarChart barChartData={dataChartDay}/>
                        </Chart>
                    )}

                    {(dataChartHours?.length > 0 && !isStock) && isTraderDasboard && (
                        <Chart title='По часам'>
                            <CustomBarChart barChartData={dataChartHours}/>
                        </Chart>
                    )}

                </div>

                {!isStock && <div
                    className={s.content_right}
                    style={{
                        display: sidebarVisible ? 'block' : 'none',
                    }}
                >
                    {sidebarVisible && (
                        <>
                            {traderDashboard && dataTrader ? (
                                <DashboardTradersSidebar dataTrader={dataTrader}/>
                            ) : dataDashboard?.product?.product_data?.type === 0 || !dataDashboard?.product?.product_data?.type ? (
                                <TradersAndSets
                                    updateDeposit={updateDeposit}
                                    assigned={dataDashboard?.product?.assigned}
                                    product={dataDashboard?.product?.product_data?.title}
                                    login={dataDashboard?.name || dataDashboard?.login}
                                />
                            ) : (
                                <RobotSettings
                                    updateDeposit={updateDeposit}
                                    login={dataDashboard?.name || dataDashboard?.login}
                                    product_data={dataDashboard?.product?.product_data}
                                    isActive={dataDashboard?.product?.product_data?.is_active}
                                    id={dataDashboard?.product?.product_data?.id}
                                />
                            )}
                        </>
                    )}
                </div>}
            </div>

            {!mediaQuery && (
                <Button
                    onClick={() => setSidebarVisible(!sidebarVisible)}
                    variant='contained'
                    color={!sidebarVisible ? 'warning' : 'error'}
                    sx={{height: 48, position: 'fixed', right: 20, left: 20, bottom: 40}}
                >
                    {!sidebarVisible ? 'Настройки' : 'Выйти'}
                </Button>
            )}
        </>
    );
};

export default Tabs1;
