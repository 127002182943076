import React, {FC, useEffect, useState} from 'react';
import MyProductItem from "../entities/components/myProductItem";
import Paper from "@mui/material/Paper";
import {Pagination, Stack} from "@mui/material";
import {useGetAllProductsQuery} from "../store/API/productApi";
import PaymentModal from "../entities/components/modal/paymentModal";
import {useSearchParams} from "react-router-dom";

interface IType {
    children?: any
}

const MyProductsList: FC<IType> = ({children}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [page, setPage] = React.useState(1);

    const [openPaymentModal, setOpenPaymentModal] = useState<any>(false)
    const {data, isLoading, error} = useGetAllProductsQuery(page, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


    useEffect(() => {
        if (searchParams.get('payment_product')) {
            setOpenPaymentModal(searchParams.get('payment_product'))
            searchParams.delete('payment_product')
            setSearchParams(searchParams)
        }
    }, [searchParams])

    return (
        <Paper>
            {
                openPaymentModal &&
                <PaymentModal title="Продление продукта" text="Вы хотите продлить продукт"
                              paymentLinkId={openPaymentModal}
                              openModal={openPaymentModal}
                              closeModal={setOpenPaymentModal}/>
            }
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{mb: 7}}>
                <span className="h2 white-90">Мои продукты</span>
            </Stack>
            <Stack spacing={7}>
                {
                    data && data.data.map(item => {

                            return <MyProductItem
                                setOpenPaymentModal={() => setOpenPaymentModal(item?.id)}
                                openPaymentModal={openPaymentModal}
                                isRobotPay={Boolean((item?.status === 1 || item?.status === 2) && item?.type === 1)}
                                type={item.type}
                                account_type={item.account_type}
                                id={item.id}
                                key={item.id}
                                slug={item.slug}
                                accountData={item?.forex_account?.account_data}
                                sub_title={item.sub_title}
                                status={item.status}
                                valid_to={item.valid_to}
                            />
                        }
                    )
                }
                {
                    data?.meta?.pagination?.total_pages > 1 &&
                    <Pagination
                        onChange={handleChange}
                        color="primary"
                        count={data?.meta?.pagination?.total_pages}
                        variant="outlined"
                        shape="rounded"/>
                }
            </Stack>
        </Paper>
    );
};

export default MyProductsList;
