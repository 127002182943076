import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {ReactComponent as Icon1} from '../../../../shared/assets/images/trade_icon/1.svg'
import {ReactComponent as Icon2} from '../../../../shared/assets/images/trade_icon/2.svg'
import {ReactComponent as Icon3} from '../../../../shared/assets/images/trade_icon/3.svg'
import {useSearchParams} from "react-router-dom";


const TraderMain = ({data}: any) => {
    const [params, setParams] = useSearchParams()
    return (
        <div className={s.main}>
            <h3 className={s.title}>Все стратегии</h3>

            <div className={s.items}>
                {data?.map((el: any, i: number) => {
                    return <div className={s.item} key={i} onClick={() => {
                        params.set('type', el.param)
                        setParams(params)
                    }}>
                        <div className={s.item_left}>
                            {el.icon}
                            <p className={s.item_left_title}>{el.title}</p>
                        </div>
                        <div className={s.item_right}>
                            <div className={s.item_right_item}>
                                <p className={s.item_right_title}>Сумма закупа</p>
                                <p className={s.item_right_sub_title}>{el.purchase}</p>
                            </div>

                            <div className={s.line}/>

                            <div className={s.item_right_item}>
                                <p className={s.item_right_title}>Ликвидная стоимость</p>
                                <p className={s.item_right_sub_title}>{el.liquid}</p>
                            </div>

                            <div className={s.line}/>

                            <div className={s.item_right_item}>
                                <p className={s.item_right_title}>Свободные средства</p>
                                <p className={s.item_right_sub_title}>{el.available}</p>
                            </div>

                            <div className={s.line}/>

                            <div className={s.item_right_item}>
                                <p className={s.item_right_title}>Прирост</p>
                                <p className={classNames(s.item_right_sub_title, s.item_right_sub_title_green)}>{el.growth}</p>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
};

export default TraderMain;