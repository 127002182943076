import s from './styles.module.css'
import {Box, Divider, IconButton, InputLabel, Modal, Pagination, Skeleton, Stack, Switch} from "@mui/material";
import IconClose from "../../../../shared/assets/images/icons/iconClose";
import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import {ReactComponent as HistoryIcon} from "../../../../shared/assets/images/history.svg";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {ReactComponent as PlusIcon} from '../../../../shared/assets/images/icons/plusTable.svg'
import {toast} from "react-toastify";
import {useGetStockOrBoundsDataMutation, useGetStockOrBoundsTagsQuery} from "../../../../store/API/chartApi";
import {spaceBtwHundreds} from "../../../utils/spaceBetweenSum";
import {useHorizontalScroll} from "../../../utils/horizontalScroll";
//@ts-ignore
import _debounce from 'lodash/debounce';
import TableStock from "./tableStock";
import TableBond from "./tableBond";
import SearchIcon from '@mui/icons-material/Search';
import CustomSelect from "../../../../common/select";

export function debounce(func: any, wait: any) {
    let timeout: any;
    //@ts-ignore
    return function (...args) {
        clearTimeout(timeout);
        //@ts-ignore
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export const rowStyleText = {
    fontFamily: 'Montserrat,sans-serif',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14.63px',
    textAlign: 'center',
    color: '#BDBDBD',
    borderColor: '#3C3C3C !important',

}

const optionsDurations = [
    {label: 'Все', value: 'all'},
    {label: 'Короткие', value: 'short'},
    {label: 'Средние', value: 'medium'},
    {label: 'Длинные', value: 'long'},
]

const ModalChartSettingsV2 = ({setParams, params, tools, handlerClose, open, setTools}: any) => {

    // const [tag, setTag] = useState<any>({id: null, name: 'Все'})
    const [search, setSearch] = useState<string>('')

    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState(true)

    const [getStockOrBoundsData] = useGetStockOrBoundsDataMutation()
    const {data: dataTags, isFetching} = useGetStockOrBoundsTagsQuery({type: params.type}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const handleData = (params: any) => {
        setLoading(true)
        getStockOrBoundsData(params)
            .unwrap()
            .then((res) => {
                setData(res)
            })
            .catch((e) => {
                toast.error('Ошибка загрузки данных')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function handleSearch() {
        setParams({
            ...params,
            search: search,
            page: 1
        });
        handleData({...params, search: search, page: 1})
    };

    useEffect(() => {
        setSearch(params.search)
    }, [params.search])

    useEffect(() => {
        handleData({
            ...params,
        })
    }, [params.type, params.tag?.id])

    return (
        <Modal
            open={open}
            onClose={handlerClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{maxWidth: '80%', height: '80%'}}>
                <Stack onClick={handlerClose} sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                    <IconClose/>
                </Stack>
                <Stack className="h2 white-90" sx={{mb: 7}}>Все инструменты</Stack>

                <Divider variant="fullWidth" sx={{mb: 7}}/>

                <Stack justifyContent="flex-end" spacing={7}
                       sx={{mt: 7, display: 'flex', height: 'calc(100% - 50px)'}}>
                    <div className={s.input_box}>

                        <input onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                handleSearch()
                            }
                        }} className={s.input} placeholder={'Поиск'} type="text" value={search}
                               onChange={(e: any) => setSearch(e.target.value)}/>
                        <div className={s.search_button} onClick={handleSearch}>
                            Найти
                            {/*<IconButton onClick={handleSearch}>*/}
                            {/*    <SearchIcon/>*/}
                            {/*</IconButton>*/}
                        </div>
                    </div>
                    <div className={s.choose_items}>
                        <div className={classNames(s.choose_item, params.type === 1 && s.choose_item_active)}
                             onClick={() => {
                                 setParams({
                                     ...params,
                                     sort: {path: null, sort: 1},
                                     type: 1,
                                     page: 1,
                                     tag: {id: null, name: 'Все'},
                                     search: ''
                                 })
                             }}>
                            <div className={s.choose_item_inside}>
                                <HistoryIcon/>
                                Акции
                            </div>
                        </div>
                        <div className={classNames(s.choose_item, params.type === 2 && s.choose_item_active)}
                             onClick={() => {
                                 setParams({
                                     ...params,
                                     sort: {path: null, sort: 1},
                                     type: 2,
                                     page: 1,
                                     tag: {id: null, name: 'Все'},
                                     search: ''
                                 })
                             }}>
                            <div className={s.choose_item_inside}>
                                <HistoryIcon/>
                                Облигациии
                            </div>
                        </div>
                    </div>

                    <div className={s.tags}>
                        {[
                            {id: null, name: 'Все'},
                            ...dataTags?.data || []]?.map((el: any) => {
                            return <div key={el.id}
                                        className={classNames(s.tag, params?.tag?.id === el?.id && s.tag_active)}
                                        onClick={() => setParams({...params, tag: el, page: 1})}>
                                {el.name}
                            </div>
                        })}
                    </div>

                    <div className={s.table}>

                        {params.type === 1 &&
                            <TableStock setParams={setParams} params={params} handleData={handleData} tools={tools}
                                        setTools={setTools} handlerClose={handlerClose} data={data}/>}
                        {params.type === 2 &&
                            <div className={s.select_and_table_box}>
                                <div className={s.select}>
                                    <CustomSelect handleChange={(v: any) => {
                                        setParams({...params, duration: v, page: 1})
                                        handleData({...params, duration: v, page: 1})
                                    }}
                                                  value={params?.duration} options={optionsDurations}
                                                  placeholder={'Дюрация'}/>
                                </div>

                                <TableBond setParams={setParams} params={params} handleData={handleData} tools={tools}
                                           setTools={setTools} handlerClose={handlerClose} data={data}/>
                            </div>
                        }

                        <div className={s.pagination}>
                            <Pagination
                                page={params.page}
                                onChange={(e, v) => {
                                    setParams({...params, page: v})
                                    console.log(params)
                                    handleData({...params, page: v})
                                }}
                                color="primary"
                                //@ts-ignore
                                count={(data && data?.meta && data?.meta?.pagination) ? (data?.meta?.pagination?.total_pages || 1) : 1}
                                variant="outlined"
                                shape="rounded"
                            />
                        </div>

                    </div>
                </Stack>
            </Box>
        </Modal>
    );
};

export default ModalChartSettingsV2;
