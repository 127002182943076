import React, {FC, useEffect, useState} from 'react';
import {Chip, Divider, Grid, Pagination, Stack, useMediaQuery} from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconClose from '../../../shared/assets/images/icons/iconClose';
import Button from '@mui/material/Button';
import {
    useDeleteProductMutation,
    useGetAllAddValidateProductsQuery,
    useGetAllAddValidateProductsRobotQuery,
    useUpdateProductMutation,
} from '../../../store/API/productApi';
import Paper from '@mui/material/Paper';
import {useGetAccountsQuery} from '../../../store/API/userApi';
import SimpleModal from './simpleModal';
import {toast, ToastContainer} from 'react-toastify';

interface IType {
    openModal: boolean;
    closeModal: any;
    status?: any;
    isRobotPay?: boolean;
    productId: number | string;
    sub_title?: string;
    account_type?: string;
    idAccountData: number | null;
}

const SettingProductModal: FC<IType> = ({
                                            openModal,
                                            account_type,
                                            isRobotPay,
                                            status,
                                            closeModal,
                                            productId,
                                            idAccountData,
                                            sub_title
                                        }) => {
    const [accountsPage, setAccountsPage] = useState(1);
    const [updateAccount, {isLoading, error}] = useUpdateProductMutation();
    const [deleteProduct] = useDeleteProductMutation();
    const {
        data: accountsData,
        error: accountsError,
        isLoading: accountsLoading,
    } = useGetAllAddValidateProductsRobotQuery(
        {page: accountsPage, id: productId},
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [confirmModalRobot, setConfirmModalRobot] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [modalSave, setModalSave] = useState(false);
    const [_, setStep] = useState(1);
    const [forexAccountData, setForexAccountData] = useState<{ id: number | null; login: string }>({
        id: null,
        login: '',
    });

    const [forexProductTitle, setForexProductTitle] = useState('');
    const [forexProductId, setForexProductId] = useState(0);
    useEffect(() => {
        setOpen(openModal);
    }, [open, openModal]);

    const handlerClose = () => {
        closeModal(false);
        setOpen(false);
        setStep(1);
    };
    const handleChangeProductsPage = (event: React.ChangeEvent<unknown>, value: number) => {
        setAccountsPage(value);
    };
    const handleUpdate = () => {
        updateAccount({
            body: {trading_account_id: forexAccountData.id == 0 ? null : forexAccountData.id},
            id: productId,
        }).then(() => {
            if (!error && !isLoading) {
                closeModal(false);
                setConfirmModalRobot(false);
            }
        });
    };
    const handleChangeAccount = (productData: any, id: number | null, login: string) => {
        if (productData) setOpenUpdateModal(true);
        setForexProductTitle(productData?.sub_title);
        setForexProductId(productData?.id);
        setForexAccountData({
            id: id,
            login: login ? login : '',
        });
    };
    const handleUpdateAccount = () => {
        updateAccount({
            body: {trading_account_id: null},
            id: forexProductId,
        }).then(() => {
            handleUpdate();
            if (!error && !isLoading) {
                setOpenUpdateModal(false);
            }
        });
    };

    const handleDeleteProduct = async () => {
        try {
            const res: any = await deleteProduct({
                id: productId,
            });
            if (res?.error) {
                toast.error('Ошибка!', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                handlerCloseDeleteModal();
                return;
            }
            toast.success('Удалено!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            handlerClose();
            handlerCloseDeleteModal();
        } catch (e) {
            console.log(e);
            console.log('error');
            toast.error('Ошибка!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
    };

    const handlerCloseDeleteModal = () => setOpenDeleteModal(false);
    return (
        <>
            <Modal
                open={open}
                onClose={handlerClose}
                sx={{
                    '@media (max-width:980px)': {
                        maxWidth: '95%',
                        width: '100%',
                        margin: '0 auto',
                    },
                }}
            >
                <Box sx={{maxWidth: 780}}>
                    <Stack onClick={handlerClose} sx={{position: 'absolute', top: 14, right: 28, cursor: 'pointer'}}>
                        <IconClose/>
                    </Stack>
                    {/*<Stack className="h2 white-90" sx={{mb: 7}}>Копировальщик до 10.000$</Stack>*/}
                    <Stack className='h2 white-90' sx={{mb: 7}}>
                        {sub_title}
                    </Stack>

                    <Divider variant='fullWidth' sx={{mb: 7}}/>
                    <Stack spacing={7}>
                        <Stack className='h2 white-100'>Выберите счет для продукта</Stack>
                        {accountsData &&
                            accountsData.data.map((item: any) => (
                                <Paper
                                    key={item.id}
                                    onClick={() => {
                                        if (item.id === idAccountData) {
                                            setForexAccountData({id: null, login: ''});
                                            return;
                                        }
                                        handleChangeAccount(item?.product?.product_data, item.id, item.login);
                                    }}
                                    sx={{
                                        borderColor: forexAccountData.id === item.id || (item.id === idAccountData && forexAccountData.id === null) ? '#6FCF97' : '',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Grid container spacing={10} columns={10} wrap='wrap' alignItems='center'>
                                        <Grid item xs={5} md={2}>
                                            <Stack spacing={2} sx={{width: 120}}>
                                                <Stack
                                                    sx={{
                                                        display: 'block',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    className='subHeaders white-90'
                                                >
                                                    {item.login}
                                                </Stack>
                                                <Stack
                                                    sx={{
                                                        display: 'block',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    className='subHeadersBold'
                                                >
                                                    {item.name ? item.name : '---'}
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={5} md={4}>
                                            {item.product?.assigned ? (
                                                <Chip
                                                    color='info'
                                                    label={`${item.product?.product_data?.sub_title}`}
                                                    sx={{maxWidth: 210, width: `100%`, cursor: 'pointer'}}
                                                />
                                            ) : (
                                                <Chip
                                                    sx={{maxWidth: 210, width: `100%`, cursor: 'pointer'}}
                                                    variant='outlined'
                                                    color={forexAccountData.id === item.id ? 'success' : 'neutral'}
                                                    label={forexAccountData.id === item.id ? 'Выбрано' : 'Свободен для привязки'}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Stack direction='row' justifyContent='space-between' spacing={7}>
                                                <Chip
                                                    label={item?.server.type === 0 ? 'Центовый' : 'Долларовый'}
                                                    variant='filled'
                                                    color={item?.server.type === 0 ? 'neutral' : 'warning'}
                                                    sx={{width: 108, pr: 0, pl: 0}}
                                                />
                                                <Stack alignItems='center' spacing={2}>
                                                    <span className='subHeaders white-90'>Прирост</span>
                                                    <span
                                                        className={item.stats?.balance?.gain.percent > 0 ? 'subHeadersBold green' : 'subHeadersBold red'}>
														{item.stats?.balance?.gain.percent}%
													</span>
                                                </Stack>
                                                <Divider orientation='vertical' flexItem/>
                                                <Stack alignItems='center' spacing={2}>
                                                    <span className='subHeaders white-90'>Баланс</span>
                                                    <span
                                                        className='subHeaders yellow'>{item.stats?.balance?.value}</span>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}

                        {accountsData?.meta?.pagination?.total_pages > 1 && (
                            <Pagination
                                page={accountsPage}
                                onChange={handleChangeProductsPage}
                                color='primary'
                                count={accountsData?.meta?.pagination?.total_pages}
                                variant='outlined'
                                shape='rounded'
                                sx={{mr: 'auto'}}
                            />
                        )}
                        <Button
                            onClick={() => {
                                setForexAccountData({id: 0, login: ''});
                            }}
                            disabled={Boolean(idAccountData === undefined)}
                            fullWidth
                            variant={forexAccountData.id === 0 ? 'contained' : 'outlined'}
                            color={forexAccountData.id === 0 ? 'success' : 'neutral'}
                            sx={{height: 68}}
                        >
                            Отвязать счет
                        </Button>
                        <Stack direction='row' spacing={7} justifyContent='flex-end'>
                            {(status === 0 && account_type === 'forex') && (
                                <Button
                                    color='error'
                                    variant='contained'
                                    sx={{
                                        maxWidth: '100%',
                                        background: '#2C2626',
                                        borderRadius: '5px',
                                    }}
                                    onClick={() => setOpenDeleteModal(true)}
                                >
                                    Удалить
                                </Button>
                            )}
                            <Button onClick={handlerClose} color='error'>
                                Отмена
                            </Button>
                            <Button
                                onClick={
                                    () =>
                                        !isRobotPay
                                            ? setModalSave(true)
                                            : //  handleUpdate()
                                            setConfirmModalRobot(true)
                                    // console.log(forexAccountData)
                                }
                                disabled={forexAccountData.id === null}
                                color='success'
                            >
                                Сохранить
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
            <SimpleModal
                maxWidth={620}
                title='Отвязать продукт'
                openModal={openUpdateModal}
                closeModal={(b: boolean) => {
                    setForexAccountData({id: null, login: ''});
                    setOpenUpdateModal(b);
                }}
            >
				<span className='h2'>
					Данный счет привязан к продукту <span className='blue'>{forexProductTitle}</span>. Хотите отвязать этот продукт и привязать к новому?
				</span>
                <Stack
                    direction='row'
                    justifyContent='flex-end'
                    spacing={7}
                    sx={{
                        marginTop: '10px',
                    }}
                >
                    <Button
                        onClick={() => {
                            setForexAccountData({
                                id: null,
                                login: '',
                            });
                            setOpenUpdateModal(false);
                        }}
                        color='error'
                    >
                        Отмена
                    </Button>
                    <Button onClick={handleUpdateAccount} color='success'>
                        Подтвердить
                    </Button>
                </Stack>
            </SimpleModal>
            <Modal
                open={openDeleteModal}
                onClose={handlerCloseDeleteModal}
                aria-labelledby='parent-modal-title'
                aria-describedby='parent-modal-description'
            >
                <Box sx={{maxWidth: 620}}>
                    <Stack onClick={handlerCloseDeleteModal}
                           sx={{position: 'absolute', top: 14, right: 28, cursor: 'pointer'}}>
                        <IconClose/>
                    </Stack>
                    <Stack className='h2 white-90' sx={{mb: 7}}>
                        Удалить продукт
                    </Stack>

                    <Divider variant='fullWidth' sx={{mb: 7}}/>

                    <div className='h2'>
                        <span className='red'>Удалить </span>
                        <span>продукт&nbsp;{sub_title}?</span>
                    </div>

                    <Stack direction='row' justifyContent='flex-end' spacing={7} sx={{mt: 7}}>
                        <Button onClick={handlerCloseDeleteModal} color='error'>
                            Отмена
                        </Button>

                        <Button onClick={handleDeleteProduct} color='success'>
                            Подтвердить
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            {confirmModalRobot && (
                <SimpleModal
                    maxWidth={620}
                    title='Вы уверены что хотите отвязать счет от продукта робот?'
                    openModal={confirmModalRobot}
                    closeModal={() => {
                        setConfirmModalRobot(false);
                    }}
                >
					<span className='h2'>
						После отвязки счета, вы сможете привязать этот счет обратно. <br/>
						Другие счета можно привязать только после окончания срока действия продукта или приобрести новый.
					</span>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        spacing={7}
                        sx={{
                            marginTop: '10px',
                        }}
                    >
                        <Button
                            onClick={() => {
                                setConfirmModalRobot(false);
                            }}
                            color='error'
                        >
                            Отмена
                        </Button>
                        <Button onClick={handleUpdate} color='success'>
                            Подтвердить
                        </Button>
                    </Stack>
                </SimpleModal>
            )}
            {modalSave && (
                <SimpleModal
                    maxWidth={620}
                    title='Подтверждение'
                    openModal={modalSave}
                    closeModal={() => {
                        setModalSave(false);
                    }}
                >
                    {forexAccountData?.id !== 0 && (
                        <>
                            {idAccountData === undefined && (
                                <span className='h2'>
									Вы уверены что хотите привязать счет <span
                                    className='green'> {forexAccountData?.login}</span> к продукту{' '}
                                    <span className='blue'>{sub_title}</span> ?
								</span>
                            )}
                            {idAccountData !== undefined && (
                                <span className='h2'>
									Вы уверены что хотите изменить текущий счет и подключить счет <span
                                    className='green'>{forexAccountData?.login}</span> к продукту{' '}
                                    <span className='blue'>{sub_title}</span>.
									<br/> На текущем счете необходимо закрыть все сделки вручную.
								</span>
                            )}
                        </>
                    )}

                    {forexAccountData?.id === 0 && (
                        <span className='h2'>
							Вы уверены что хотите отключить счет{' '}
                            <span
                                className='green'> {accountsData && accountsData?.data.find((f: any) => f.id === idAccountData)?.login}</span> от продукта{' '}
                            <span className='blue'>{sub_title}</span>.<br/> При отключении вам необходимо будет закрыть все открытые сделки вручную.
						</span>
                    )}
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        spacing={7}
                        sx={{
                            marginTop: '10px',
                        }}
                    >
                        <Button
                            onClick={() => {
                                setModalSave(false);
                            }}
                            color='error'
                        >
                            Отмена
                        </Button>
                        <Button onClick={handleUpdate} color='success'>
                            Подтвердить
                        </Button>
                    </Stack>
                </SimpleModal>
            )}
        </>
    );
};

export default SettingProductModal;
