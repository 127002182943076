import React, {useContext} from 'react';
import Tabs1 from "../tabs_1";
import {DashboardContext} from "../dashboardTabs";

const ChartPage = () => {
    const {
        isTraderDasboard,
        traderDashboard,
        dataTrader,
        isLoadingAccount,
        dataDashboard,
        balanceChartData,
        balanceChartUrl,
        dataBalanceGain,
        balanceGainChartUrl,
        dataChartDrawdownAndGain,
        drawdownAndGainChartUrl,
        dataChartDrawdown,
        drawdownChartUrl,
        dataTradersChart,
        tradersChartUrl,
        dataSymbol,
        barChartData,
        dataChartDay,
        dataChartHours,
        dataChartMonths,
        sidebarVisible,
        mediaQuery,
        updateDeposit,
        setSidebarVisible,
        isTrader,
        isStock
    } = useContext(DashboardContext)
    return (
        <Tabs1 isTraderDasboard={isTraderDasboard} traderDashboard={traderDashboard} dataTrader={dataTrader}
               isLoadingAccount={isLoadingAccount}
               isStock={isStock}
               dataDashboard={dataDashboard} balanceChartData={balanceChartData}
               balanceChartUrl={balanceChartUrl} dataBalanceGain={dataBalanceGain}
               balanceGainChartUrl={balanceGainChartUrl}
               dataChartDrawdownAndGain={dataChartDrawdownAndGain}
               drawdownAndGainChartUrl={drawdownAndGainChartUrl}
               dataChartDrawdown={dataChartDrawdown}
               drawdownChartUrl={drawdownChartUrl}
               dataTradersChart={dataTradersChart}
               tradersChartUrl={tradersChartUrl}
               dataSymbol={dataSymbol}
               barChartData={barChartData}
               dataChartDay={dataChartDay}
               dataChartHours={dataChartHours}
               dataChartMonths={dataChartMonths}
               sidebarVisible={sidebarVisible}
               mediaQuery={mediaQuery}
               updateDeposit={updateDeposit}
               setSidebarVisible={setSidebarVisible}/>
    );
};

export default ChartPage;