import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const Navigate = ({handleBack, title}: any) => {
    return (
        <div className={s.navigate}>
            <p style={{cursor: 'pointer'}} className={s.title} onClick={handleBack}>Все стратегии</p>
            <p className={s.title}>/</p>
            <p className={classNames(s.title, s.title_last)}>{title}</p>
        </div>
    );
};

export default Navigate;