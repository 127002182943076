import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const CustomButton = ({children, onClick, className}: any) => {
    return (
        <div className={classNames(s.btn, className)} onClick={onClick}>
            {children}
        </div>
    );
};

export default CustomButton;