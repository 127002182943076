import React, {FC} from 'react';
import {Avatar, Stack, useMediaQuery} from "@mui/material";
import img from '../../shared/assets/images/amin-panel.png'
import {useGetProfileQuery} from "../../store/API/profileApi";

interface T {
    isAdmin?: boolean;
    hiddeSideBar?: boolean;
}

const HeaderSidebar: FC<T> = ({isAdmin, hiddeSideBar}) => {
    const {data, error, isLoading} = useGetProfileQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const mediaQuery = useMediaQuery('(min-width:980px)');
    return (
        <Stack direction="row" spacing={7}>
            {
                (isAdmin && mediaQuery) ?
                    <Stack>
                        <img src={img} alt="image"/>
                    </Stack>
                    : (!isAdmin) ?
                        <>
                            <Avatar
                                alt={data?.data?.telegram?.fullname}
                                src={data?.data?.telegram?.avatar_url && data?.data?.telegram?.avatar_url}
                                sx={{width: mediaQuery ? 74 : 34, height: mediaQuery ? 74 : 34}}
                            />
                            {!hiddeSideBar && <Stack
                                flexGrow={1}
                                spacing={2}
                                direction={mediaQuery ? "column" : "row"}
                                justifyContent={mediaQuery ? "flex-start" : "space-between"}
                            >
                                <Stack justifyContent={"center"} sx={{
                                    height: '100%'
                                }}>
                                    <div className="subHeaders white-80">@{data?.data?.telegram?.username}</div>
                                    <div className="h2">{data?.data?.telegram?.fullname}</div>
                                </Stack>
                            </Stack>}
                        </>
                        : null
            }
        </Stack>
    );
};

export default HeaderSidebar;
