import React, {useContext} from 'react';
import Tabs2 from "../tabs_2";
import {DashboardContext} from "../dashboardTabs";

const HistoryPage = () => {
    const {
        isTrader
    } = useContext(DashboardContext)
    return (
        <Tabs2 isTrader={isTrader}
            // dataTrader={dataTrader}
               dataTrader={null}
        />
    );
};

export default HistoryPage;