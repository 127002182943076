import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const InfoBlock = () => {
    return (
        <div className={s.main}>
            <div className={classNames(s.item)}>
                <p className={s.title}>Загрузка депозита
                    <br/>
                    1 сетка</p>
                <p className={classNames(s.sub_title)}>12.50% - 29.2932₽</p>
            </div>
            <div className={classNames(s.item, s.item_gold)}>
                <p className={s.title}>
                    Загрузка депозита
                    <br/>
                    В общем
                </p>
                <p className={classNames(s.sub_title, s.sub_title_gold)}>50.50% - 109.2034₽</p>
            </div>
        </div>
    );
};

export default InfoBlock;