import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {Alert, Divider, Snackbar, Stack} from "@mui/material";
import {FC, useEffect, useState} from "react";
import IconClose from "../../../shared/assets/images/icons/iconClose";
import CustomInput from "../../../shared/UI/customInput";
import {useAddAccountMutation, useUpdateAccountMutation} from "../../../store/API/userApi";
import {useInput} from "../../../hooks/useInput";
import CustomSelect from "../../../shared/UI/customSelect";
import {useGetServersQuery} from "../../../store/API/userApi";
import ModalInfoAddAccount from "./modal_info_add_account";


interface IType {
    openModal: boolean | string;
    closeModal: any;
    account?: any;
    isOPenBtn?: boolean
    isError?: boolean
    updateAccount?: boolean
}

const AccountModal: FC<IType> = ({account, openModal, closeModal, isError, updateAccount}) => {
    const {data: isDataServer, isLoading: isLoadingDataServer} = useGetServersQuery(openModal ? openModal : '', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const [addAccount, {error: addError, isLoading}] = useAddAccountMutation()
    const [update] = useUpdateAccountMutation()

    const [openModalInfo, setOpenModalInfo] = useState(false)
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(updateAccount ? 2 : 1);
    const [serverNumber, setServerNumber] = useState(updateAccount ? account.server.id : '');
    const [errorInputLogin, setErrorInputLogin] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const login = useInput(updateAccount ? account.accountLogin : '', errorInputLogin)
    const password = useInput(updateAccount ? account.accountPassword : '', errorPassword)
    const [errorSelect, setErrorSelect] = useState(false);
    const [titleLoginError, setTitleLoginError] = useState<string | null>(null)

    useEffect((() => {
        setOpen(Boolean(openModal))
    }), [open, openModal])

    const handlerClose = (e?: any) => {
        closeModal(false)
        setOpen(false);
        setErrorSelect(false);
        setErrorInputLogin(false);
        setErrorPassword(false);
        setServerNumber('')
        setStep(1)
        if (e) {
            login.onChange(e)
            password.onChange(e)
        }

    };

    const handlerAddAccount = async () => {
        setErrorSelect(false)
        setErrorInputLogin(false)
        setErrorPassword(false)

        if (login.value !== '' && password.value !== '' && serverNumber !== '') {
            if (updateAccount) {
                await update({
                    body: {
                        server_id: serverNumber,
                        login: login.value,
                        password: password.value,
                    },
                    id: account.accountId
                })
                handlerClose()
            } else {
                addAccount({
                    server_id: serverNumber,
                    login: login.value,
                    password: password.value,
                    trading_type: openModal
                }).then((res: any) => {
                    if (res.error) {
                        setErrorInputLogin(true)
                        setTitleLoginError(res.error.data.message)

                        return
                    }

                    setStep(step + 1)
                }).catch((e) => {
                        console.log(e)
                    }
                )

            }
        } else {
            if (serverNumber === '') {
                setTimeout(() => setErrorSelect(true), 0)
            }
            if (login.value === '') {
                setTimeout(() => {
                    setErrorInputLogin(true)
                    setTitleLoginError(null)
                }, 0)
            }
            if (password.value === '') {
                setTimeout(() => setErrorPassword(true), 0)
            }
        }
    };

    return (
        <>
            {openModalInfo && <ModalInfoAddAccount confirm={() => {
                setStep(2)
                setOpenModalInfo(false)
            }} open={openModalInfo} handlerClose={() => setOpenModalInfo(false)}/>}
            <Modal open={open} onClose={handlerClose}>
                <Box sx={{maxWidth: 460}}>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center',}}
                        open={step === 3}
                    >
                        <Alert severity={addError ? "error" : "success"} icon={false}>
                            {addError ? 'Ошибка' : 'Успешно!'}
                        </Alert>
                    </Snackbar>
                    <Stack onClick={handlerClose} sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                        <IconClose/>
                    </Stack>
                    <Stack className="h2 white-90" sx={{mb: 7}}>{updateAccount ? 'Изменить' : 'Добавить счет'}</Stack>
                    <Divider variant="fullWidth" sx={{mb: 7}}/>
                    {
                        (step === 1) ?
                            <div className="h2">
                                <span>Для работы в системе CopyTen, допускаются только счета находящиеся в партнерской программе брокера<span
                                    className={'blue'}> {openModal === 'forex' ? 'Forex4you' : 'Финам'}</span> </span>
                                {/*<br/>*/}
                                {/*<a href={'https://telegra.ph/Registraciya-v-partnyorskoj-programme-05-20'}*/}
                                {/*   target={'_blank'} className="blue">Инструкция </a>*/}
                                <Button onClick={() => setOpenModalInfo(true)} sx={{
                                    width: '100%',
                                    padding: '14px 28px',
                                    marginTop: '14px',
                                    color: '#56CCF2',
                                    fontSize: '16px',
                                    fontWeight: 600
                                }}>Инструкция</Button>
                            </div>
                            : (step === 2) ?
                                <Stack justifyContent="flex-end" spacing={7} sx={{mt: 7}}>
                                    <CustomInput label="Номер счета" dataInput={login} titleError={titleLoginError}
                                                 inputType="text"/>
                                    <CustomInput label="Пароль от счета" dataInput={password}/>
                                    <Stack direction="row" sx={{width: '100%'}} justifyContent="space-between" spacing={7}>
                                        <span style={{width: '50%'}} className="subHeadersBold white-90">Сервер счета</span>
                                        <CustomSelect
                                            width={'calc(50% + 28px)'}
                                            defaultValue={updateAccount ? account.server.title : "Выбрать сервер"}
                                            optionValue={setServerNumber}
                                            options={isDataServer?.data}
                                            isError={errorSelect}
                                        />
                                    </Stack>
                                </Stack>
                                :
                                (step === 3 && addError) ?
                                    <div className="h2">
                                        <span className="red">Ошибка!</span>
                                        <span> Заявка на добавление счета не отправлена</span>
                                    </div>
                                    : <div className="h2">
                                        <span className="green">Успешно!</span>
                                        <span> Заявка на добавление счета отправлена</span>
                                    </div>

                    }
                    <Stack direction="row" justifyContent="flex-end" spacing={7} sx={{mt: 7}}>
                        {
                            (step === 3) ?
                                null
                                :
                                <Button onClick={handlerClose} color="error">Отмена</Button>

                        }

                        {
                            (step === 2) ?
                                <Button onClick={handlerAddAccount} color="success">Продолжить</Button>
                                :
                                (step === 3 && !isError) ?
                                    <Button onClick={handlerClose} color="success">Закрыть</Button>
                                    :
                                    <Button onClick={() => setStep(step + 1)} color="success">Продолжить</Button>
                        }

                    </Stack>
                </Box>
            </Modal>
        </>
    );
}


export default AccountModal
