import React, {FC, useEffect, useState} from 'react';
import {useInput} from "../../../hooks/useInput";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {Alert, Divider, Snackbar, Stack} from "@mui/material";
import IconClose from "../../../shared/assets/images/icons/iconClose";
import CustomInput from "../../../shared/UI/customInput";
import Button from "@mui/material/Button";
import CustomSelect from "../../../shared/UI/customSelect";
import {
    useAddServerMutation,
    useDeleteServerMutation,
    useUpdateServerMutation
} from "../../../store/API/serverApi";

interface IType {
    maxWidth?: number;
    openModal: boolean;
    closeModal: any;
    title?: string;
    updateServerNumber?: number;
    serverName?: string;
    isAddServer?: boolean;
    isUpdateServer?: boolean;
    isDeleteServer?: boolean;
    valuesServerBroker?: any;
}

const ServerModal: FC<IType> = ({
                                    valuesServerBroker,
                                    openModal,
                                    closeModal,
                                    title,
                                    maxWidth,
                                    serverName,
                                    updateServerNumber,
                                    isAddServer,
                                    isUpdateServer,
                                    isDeleteServer,
                                }) => {

    const [addServer, {error, isLoading}] = useAddServerMutation()
    const [updateServer, {error: updateError, isLoading: isLoadingUpdate}] = useUpdateServerMutation()
    const [deleteServer, {error: deleteError, isLoading: isLoadingDelete}] = useDeleteServerMutation()
    const [open, setOpen] = useState(false);
    const [errorInput, setErrorInput] = useState(false);
    const [errorSelect, setErrorSelect] = useState(false);
    const [errorSelectv2, setErrorSelectv2] = useState(false);
    const input = useInput(valuesServerBroker !== null ? valuesServerBroker?.title : '', errorInput)
    const [serverNumber, setServerNumber] = useState((valuesServerBroker?.type !== null || valuesServerBroker?.type !== undefined) ? valuesServerBroker?.type : '');
    const [serverType, setServerType] = useState((valuesServerBroker?.trading_type !== null || valuesServerBroker?.trading_type !== undefined) ? valuesServerBroker?.trading_type : '');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [_, setErrorValues] = useState<any>({})

    useEffect((() => {
        // setServerNumber()
        setOpen(openModal)
    }), [open, openModal, openSnackbar])

    const handlerAdd = () => {
        if (isDeleteServer) {
            deleteServer(updateServerNumber).then(() => {
                    if (deleteError !== undefined) {
                        setOpenSnackbar(true)
                    } else if (!isLoadingDelete) {
                        closeModal(false)
                        setOpen(false);
                    }
                }
            )
        }

        if (input.value !== '' && serverNumber !== '' && serverType !== '') {
            if (isAddServer) {
                if ((input.value === '' || input.value === undefined) || (serverNumber === '' || serverNumber === undefined) || (serverType === '' || serverType === undefined)) {
                    setErrorInput((input.value === '' || input.value === undefined))
                    setErrorSelect((serverNumber === '' || serverNumber === undefined))
                    setErrorSelectv2((serverType === '' || serverType === undefined))
                } else {
                    addServer({title: input.value, type: serverNumber, trading_type: serverType})
                        .then(() => {
                            if (!isLoading) {
                                closeModal(false)
                                setOpen(false)
                                setErrorValues({})
                            }
                        })
                        .then(() => {
                            if (error !== undefined) setOpenSnackbar(true)
                        })
                }

            }

            if (isUpdateServer) updateServer({
                body: {title: input.value, type: serverNumber, trading_type: serverType},
                id: updateServerNumber
            })
                .then(() => {
                    if (!isLoadingUpdate) {
                        closeModal(false)
                        setOpen(false);
                    }
                })
                .then(() => {
                    if (updateError !== undefined) setOpenSnackbar(true)
                })
        }
        if (input.value === '') setErrorInput(true)
        if (serverNumber === '') setErrorSelect(true)
        if (serverType === '') setErrorSelectv2(true)
    }
    const handlerClose = (e: any) => {
        closeModal(false)
        setOpen(false);
        input.onChange(e)
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center',}}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert severity="error" icon={false}>Ошибка</Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={handlerClose}
                aria-describedby="parent-modal-description"
            >
                <Box sx={{maxWidth: maxWidth ? maxWidth : 620}}>
                    <Stack onClick={handlerClose} sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                        <IconClose/>
                    </Stack>
                    <Stack className="h2 white-90" sx={{mb: 7}}>{title}</Stack>

                    <Divider variant="fullWidth" sx={{mb: 7}}/>
                    {
                        isDeleteServer ?
                            <Stack spacing={7}>
                                <div className="h2">
                                    <span>Удалить</span>
                                    <span className="blue">&nbsp;Сервер {serverName}</span>
                                    <span>?</span>
                                </div>
                            </Stack>
                            :

                            <Stack spacing={7}>
                                <CustomInput inputType="text" dataInput={input} label="Название сервера"/>
                                <Stack spacing={0} flexDirection={'row'} gap={14} width={'100%'}>
                                    <CustomSelect
                                        isNeedLeft={false}
                                        width={'50%'}
                                        smallError
                                        title="Сервер счета"
                                        defaultValue="Выбрать сервер"
                                        isSettingsParams={isAddServer ? '' : serverNumber?.toString()}
                                        optionValue={setServerNumber}
                                        options={[
                                            {
                                                title: 'Центовый',
                                                id: 0
                                            },
                                            {
                                                title: 'Долларовый',
                                                id: 1
                                            },
                                            {
                                                title: 'Рублевый',
                                                id: 2
                                            }]}
                                        isError={errorSelect || false}
                                    />
                                    <CustomSelect
                                        isNeedLeft={false}
                                        width={'50%'}
                                        smallError
                                        disabled={!isAddServer}
                                        title="Тип сервера"
                                        defaultValue="Тип сервера"
                                        isSettingsParams={isAddServer ? '' : serverType?.toString()}
                                        optionValue={setServerType}
                                        options={[
                                            {
                                                title: 'Форекс',
                                                id: 'forex'
                                            },
                                            {
                                                title: 'Фонд',
                                                id: 'stock'
                                            },
                                        ]}
                                        isError={errorSelectv2 || false}
                                    />
                                </Stack>
                            </Stack>
                    }
                    <Stack direction="row" justifyContent="flex-end" spacing={7} sx={{mt: 7}}>
                        <Button onClick={handlerClose} color="error">Отмена</Button>
                        <Button onClick={handlerAdd}
                                color="success">{isDeleteServer ? "Подтвердить" : "Добавить"}</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default ServerModal;
