import React, {FC, useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import Header from "./header";
import Footer from "./footer";
import {useGetProfileQuery} from "../store/API/profileApi";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../hooks/useRedux";


interface MainLayoutProps {
    children: any;
    isLink?: any;
    isAdmin?: boolean;
    isSelect?: boolean;
    heading?: string;
    accountNumber?: string;
    typeAccount?: string
    fullHeight?: boolean
}

const MainLayout: FC<MainLayoutProps> = ({
                                             fullHeight = false,
                                             children,
                                             isLink,
                                             isSelect,
                                             isAdmin,
                                             heading,
                                             accountNumber,
                                             typeAccount
                                         }) => {
    const {data, error} = useGetProfileQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const [customError, setCustomError] = useState<any>({})
    const navigate = useNavigate()

    useEffect(() => {
        if (error) setCustomError({...error})
        if (customError?.status === 401) {
            localStorage.clear()
            navigate('/auth')
        }
    }, [error, customError])
    return (
        <Container
            // maxWidth="lg"
            // maxWidth="100%"
            sx={{maxWidth: '100% !important'}}
            id={'test'}>
            <Grid container spacing={10} columns={16} wrap="wrap">
                <Grid item xs={16} md={12} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: `100vh`,
                    maxWidth: '100% !important',
                    flexBasis: '100% !important'
                }}>
                    {isSelect &&
                        <Header isLink={isLink} heading={heading} isSelect={isSelect} accountNumber={accountNumber}
                                typeAccount={typeAccount}/>}
                    <main className="main" style={{
                        height: fullHeight ? '100%' : ''
                    }}>
                        {children}
                    </main>
                    <Footer/>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MainLayout;
