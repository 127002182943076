import React, {useEffect, useLayoutEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Divider,
    Skeleton,
    Stack,
    useMediaQuery
} from '@mui/material';
import CustomRange from '../shared/components/customRange';
import {
    useGetRobotSettingsMutation,
    useRobotSettingsSendMutation,
    useSetEnableDisableRobotMutation
} from '../store/API/productApi';
import {toast, ToastContainer} from 'react-toastify';
import RobotModalEnableDisable from '../entities/components/modal/robotModalEnableDisable';

const RobotSettings = ({id, isActive, product_data, updateDeposit, login}: any) => {
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [getRobotSettings] = useGetRobotSettingsMutation();
    const [robotSettingsSend] = useRobotSettingsSendMutation();
    const [setEnableDisableRobot, {isLoading: isLoadingSet}] = useSetEnableDisableRobotMutation();

    const [showNotifRisk, setShowNotifRisk] = useState<any>({
        isOpen: false,
        isFirst: true,
        type: null
    })

    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const [data, setData] = useState<any>({})
    const [settingsValue, setSettingsValue] = useState<any>({
        risk: 0,
        fix_lose: 0,
        stop_at_dropdown: 40,
        drying: false,
        take_dropdown_all_pairs: true,
        // remember_balance: true,
        starting_after_stopping: true,
    });

    const [openModalFixRisk, setOpenModalFixRisk] = useState(false)

    const changeStatusRobot = async () => {
        try {
            await setEnableDisableRobot({id: id, type: isActive ? 'disable' : 'enable'});
            updateDeposit();
            setOpenModal(false);
            toast.success(isActive ? 'Отключено!' : 'Включено!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        } catch (e) {
            console.log(e);
        }
    };

    const confirmChanges = async () => {
        setIsLoadingDelete(true);
        try {
            const res = await robotSettingsSend({
                id,
                body: {
                    ...settingsValue,
                    fix_lose: settingsValue?.fix_lose === null ? 0 : settingsValue?.fix_lose,
                    take_dropdown_all_pairs: true
                }
            });
            setIsLoadingDelete(false);
            toast.success('Сохранено!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        } catch (e) {
            setIsLoadingDelete(false);
            console.log(e);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getRobotSettings(id)
            .then((res: any) => {
                setData(res?.data)
                setSettingsValue({
                    risk: res?.data?.risk,
                    fix_lose: res?.data?.fix_lose,
                    stop_at_dropdown: res?.data?.stop_at_dropdown,
                    drying: res?.data?.drying,
                    take_dropdown_all_pairs: res?.data?.take_dropdown_all_pairs || true,
                    // remember_balance: res?.data?.remember_balance,
                    starting_after_stopping: res?.data?.starting_after_stopping,
                });
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }, []);
    console.log(+settingsValue.fix_lose === 0)

    useEffect(() => {
        if (settingsValue?.fix_lose === 0 && !isLoading) {
            setOpenModalFixRisk(true)
        }
    }, [settingsValue?.fix_lose, isLoading])

    if (isLoading && mediaQuery) {
        return <Skeleton variant='rounded' width={`100%`} height={mediaQuery ? 433 : '100vh'}/>;
    }

    return (
        <>
            <Dialog
                sx={{
                    '& .MuiPaper-root': {
                        background: '#1F1F1F'
                    }

                }}
                open={showNotifRisk?.isOpen && showNotifRisk?.isFirst}
                onClose={() => {
                    setShowNotifRisk({...showNotifRisk, isOpen: false, isFirst: false, type: null})
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Внимание!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ваш риск ВЫШЕ максимально рекомендуемого. Это может привести к большим просадкам!!!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setShowNotifRisk({...showNotifRisk, isOpen: false, isFirst: false, type: null})
                    }} autoFocus>
                        Понятно
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                sx={{
                    '& .MuiPaper-root': {
                        background: '#1F1F1F'
                    }

                }}
                open={openModalFixRisk}
                onClose={() => {
                    setOpenModalFixRisk(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Внимание!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы отключили фиксацию убытка, это может привести к потере всего депозита!!!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenModalFixRisk(false)
                    }} autoFocus>
                        Понятно
                    </Button>
                </DialogActions>
            </Dialog>
            {openModal && (
                <RobotModalEnableDisable
                    login={login}
                    isActive={isActive}
                    isFetching={isLoadingSet}
                    open={openModal}
                    handleConfrim={changeStatusRobot}
                    handleClose={() => setOpenModal(false)}
                />
            )}
            <ToastContainer
                icon={false}
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{
                    minWidth: '300px',
                    width: 'fit-content',
                }}
                theme='light'
            />

            <Stack
                spacing={0}
                sx={
                    !mediaQuery
                        ? {
                            width: `100vw`,
                            paddingBottom: '150px !important',
                            minHeight: '100vh',
                            padding: '14px',
                            background: '#1F1F1F',
                        }
                        : null
                }
            >
                {!isLoading && (
                    <Paper
                        sx={{
                            background: '#272328 !important',
                            '@media (min-width:980px)': {
                                p: 10,
                            },
                            '@media (max-width:980px)': {
                                minHeight: '100%',
                            },
                        }}
                    >
                        <Stack spacing={7}>
                            <Stack
                                sx={{
                                    color: product_data?.slug === 'robot' ? '#BB6BD9' : '#6FCF97',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                }}
                            >
                                {(product_data?.slug === 'robot' && 'Робот Aggressive') || (product_data?.slug === 'robot2' && 'Робот Conservative') || (product_data?.title || 'Робот')}
                            </Stack>
                            <Divider variant='fullWidth' sx={{width: `calc(100%)`}}/>

                            <div
                                style={{
                                    marginTop: '14px',
                                }}
                            >
                                <CustomRange
                                    isNeedOpacity
                                    onChange={(value) => {
                                        setSettingsValue({...settingsValue, risk: value})
                                        if (value > 25) {
                                            setShowNotifRisk({...showNotifRisk, isOpen: value > 25, type: null})
                                        } else {
                                            setShowNotifRisk({
                                                ...showNotifRisk,
                                                isOpen: false,
                                                isFirst: true,
                                                type: null
                                            })
                                        }

                                    }}
                                    minValue={0}
                                    isChangeColor={Boolean(settingsValue.risk > 25)}
                                    title='Риск'
                                    defaultValue={settingsValue.risk}
                                    maxValue={50}
                                    required
                                    isNeedSwitch={false}
                                    isSwitch
                                    isSliderRange
                                />
                                <br/>
                                <CustomRange
                                    isNeedOpacity
                                    onChange={(value) => setSettingsValue({...settingsValue, fix_lose: value})}
                                    minValue={0}
                                    title='Фиксация убытка'
                                    isChangeColor={settingsValue.fix_lose === 0}
                                    defaultValue={settingsValue.fix_lose}
                                    maxValue={50}
                                    // isSwitchChecked={+settingsValue.fix_lose === 0 ? false : true}

                                    disabled={settingsValue.fix_lose === 0}
                                    required
                                    isNeedSwitch={false}
                                    isSwitch
                                    isSliderRange
                                />
                                <br/>
                                <CustomRange
                                    isNeedOpacity
                                    isChangeColor={settingsValue.fix_lose === 0}
                                    onChange={(value) => {
                                        console.log(value)
                                        setSettingsValue({
                                            ...settingsValue,
                                            fix_lose: value === null ? 0 : (data?.fix_lose || 50)
                                        })
                                    }}
                                    title='Выкл. / Вкл. фиксацию убытка'
                                    required
                                    isSwitchChecked={settingsValue.fix_lose === 0 ? false : true}
                                    isNeedSwitch={true}
                                    isSwitch
                                    isSliderRange={false}
                                />
                                <br/>
                                <CustomRange
                                    isNeedOpacity
                                    onChange={(value) => setSettingsValue({...settingsValue, stop_at_dropdown: value})}
                                    minValue={0}
                                    title='Остановка при просадке'
                                    defaultValue={settingsValue.stop_at_dropdown}
                                    maxValue={100}
                                    required
                                    isNeedSwitch={false}
                                    isSwitch
                                    isSliderRange
                                />
                                <br/>

                                <CustomRange
                                    isNeedOpacity
                                    onChange={(value) => setSettingsValue({
                                        ...settingsValue,
                                        starting_after_stopping: value === null ? false : true
                                    })}
                                    title='Авто-продолжение после остановки'
                                    required
                                    isSwitchChecked={settingsValue.starting_after_stopping}
                                    isNeedSwitch={true}
                                    isSwitch
                                    isSliderRange={false}
                                />
                                <br/>
                                {/*<CustomRange*/}
                                {/*    isNeedOpacity*/}
                                {/*    onChange={(value) => {*/}
                                {/*        setSettingsValue({*/}
                                {/*            ...settingsValue,*/}
                                {/*            remember_balance: value === null ? false : true*/}
                                {/*        })*/}
                                {/*    }}*/}
                                {/*    title='Запоминать баланс'*/}
                                {/*    required*/}
                                {/*    isSwitchChecked={settingsValue.remember_balance}*/}
                                {/*    isNeedSwitch={true}*/}
                                {/*    isSwitch*/}
                                {/*    isSliderRange={false}*/}
                                {/*/>*/}
                                {/*<br/>*/}
                                <CustomRange
                                    isNeedOpacity
                                    onChange={(value) => {
                                        setSettingsValue({
                                            ...settingsValue,
                                            drying: value === null ? false : true
                                        })
                                    }}
                                    defaultValue={settingsValue.drying === false ? null : 0}
                                    title='Сушка'
                                    required
                                    isSwitchChecked={settingsValue.drying}
                                    isNeedSwitch={true}
                                    isSwitch
                                    isSliderRange={false}
                                />

                            </div>
                        </Stack>
                        <br/>
                        <Stack
                            direction='column'
                            justifyContent='flex-end'
                            spacing={7}
                            sx={{
                                marginTop: '14px',
                            }}
                        >
                            <Button
                                disabled={isLoadingDelete}
                                sx={{
                                    background: '#29312C',
                                }}
                                onClick={confirmChanges}
                                color='success'
                            >
                                Сохранить
                            </Button>
                            <Button
                                sx={{
                                    background: '#2C2626',
                                }}
                                onClick={() => setOpenModal(true)}
                                color={isActive ? 'error' : 'success'}
                            >
                                {isActive ? 'Отключить' : 'Включить'}
                            </Button>
                        </Stack>
                    </Paper>
                )}
            </Stack>
        </>
    );
};

export default RobotSettings;
